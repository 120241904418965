import React, { useState } from 'react'
import Layout from "../layouts"
import Button from '../components/button/button';
import 'uikit/dist/css/uikit.css'
import { contactHeader } from "../utils/contactInformation"
import Banner from '../components/banner/banner'
import '../styles/contact.scss';


const Contact = () => {


    const [msgError, setMsgError] = useState(false);

    const [error, setError] = useState({
        fristNameError: '',
        surnameError: '',
        emailError: '',
        messageError: '',
    });

    const [contactMessage, setContactMessage] = useState({
        pageType: 'contact',
        firstName: '',
        surname: '',
        email: '',
        message: ''
    });

    const handleInput = async (event) => {

        event.persist();
        setContactMessage({ ...contactMessage, [event.target.name]: event.target.value })

    }

    const validate = async (e) => {

        let firstNameError = "";
        let surnameError = "";
        let emailError = "";
        let messageError = "";
        let isNotValid = false;


        if (contactMessage.firstName === "") {
            firstNameError = "This field is required.";
            isNotValid = true;
        }
        if (contactMessage.surname === "") {
            surnameError = "This field is required.";
            isNotValid = true;

        }
        if (contactMessage.email === "") {
            emailError = "This field is required.";
            isNotValid = true;

        }
        if (contactMessage.message === "") {
            messageError = "This field is required.";
            isNotValid = true;


        }

        setError({ ...error, firstNameError: firstNameError, surnameError: surnameError, emailError: emailError, messageError: messageError });
        return isNotValid;

    }

    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {

        event.preventDefault();

        let value = await validate();
        setMsgError(value);

        setLoading(true)

        var body = {
            Token: 'phARMocDrnKoPgKl0zcjMOhD8ZeaWrnn7',
            FormData: JSON.stringify(contactMessage),
            PageType: 'contact'
        };
        //console.log(body);
        // !value && fetch("https://api.phaeria.com/api/home/SendEmailNotification", {

        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: {

        //         'Content-Type': 'application/json',
        //         'redirect': 'follow',
        //         'mode': 'no-cors',
        //     }
        // }).then(response => response.text().then(function (responseText) {
        //     setLoading(false);
        //     if (response.status === 200) {
        //         setMessage("Thanks for your message!")
        //     }
        //     else {
        //         setMessage("Something gone wrong...Please try again later!")
        //     }
        // }))

        let spamDomains = ["mailinator", "aosod.com", "guerrillamail", "spam4.me", "pokemail.net", "sharklasers.com", "tcwlx.com", "yopmail.com", "nfstripss.com", "maildrop.cc"];
        let isSpam = 0
        spamDomains.forEach(function (domain, index) {
            if (JSON.stringify(contactMessage).includes(domain)) {
                isSpam = 1;
            }
        });

        if (isSpam == 0 && !value) {
            fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response =>
                response.text().then(function (responseText) {
                    console.log("response text: " + responseText);
                    setLoading(false);
                    if (response.status === 200) {
                        setMessage("Thanks for your message!")
                    }
                    else {
                        setMessage("Something gone wrong...Please try again later!")
                    }
                })
            )
        }
        else {
            setMessage("❗ Your message cannot be sent. Please try again with another email address.")
            console.log("Spam.")
        }

    }



    return (

        <Layout SEOTitle={"Contact | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

            <Banner props={contactHeader} />

            <div className="contact-grid">
                <div className="uk-grid grid-container" uk-grid="true">

                    <div className="uk-width-1-2 contact-column half-width">
                        <div className="uk-card uk-card-default uk-card-body" id="contact-info">
                            <p>If we haven’t answered all of your questions yet, just get in touch. We’re happy to help. Email us: <a href="mailto: enquiry@freemotorlegal.co.uk" className="email">enquiry@freemotorlegal.co.uk</a> or complete the form. </p>
                            <p>If you’ve had an accident or simply want to get advice, call <strong>0800 144 4488</strong>.</p>
                        </div>
                    </div>

                    <div className="uk-width-1-2 half-width">
                        <div className="uk-card uk-card-default uk-card-body contact-second-column" id="contact-column-form">
                            <form className="uk-grid-small uk-grid" uk-grid="true"
                                onSubmit={handleSubmit}
                            >
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">First Name</label>
                                    <input className={`uk-input ${error.firstNameError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={contactMessage.firstName} name="firstName" />
                                </div>

                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Surname</label>
                                    <input className={`uk-input ${error.surnameError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={contactMessage.surname} name="surname" />
                                </div>

                                <div className="uk-width-1-1@s margin-top">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Email Address</label>
                                    <input className={`uk-input ${error.emailError !== "" ? 'red-border' : ''}`} type="email" id="form-stacked-text" onChange={handleInput} value={contactMessage.email} name="email" />
                                </div>

                                <div className="uk-width-1-1@s margin-top">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Message</label>
                                    <textarea className={`uk-textarea ${error.messageError !== "" ? 'red-border' : ''}`} rows="6" onChange={handleInput} value={contactMessage.message} name="message" />
                                </div>

                                <div className="uk-width-1-1@s margin-top">
                                    <div className="margin-top contact-send-btn">
                                        <Button
                                            id={"sign-up-submit"}
                                            btnClass={"primary"}
                                            btnType={"primary"}
                                            label={`${loading == false ? 'Submit' : 'Loading...'}`}
                                            isSubmit={"true"}
                                            additionalClass={"full-width-btn"}
                                        ></Button>
                                    </div>
                                </div>

                                {
                                    msgError ? <p className="error-message">One or more fields have an error. Please check and try again.</p> : ""
                                }
                                {
                                    message ? <p className="error-message">{message}</p> : ""
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </div>





        </Layout>


    )

}

export default Contact